import React, { Component } from 'react';
import './App.css';
import logo from './Images/logo.jpeg'
import 'bootstrap/dist/css/bootstrap.min.css';
import Countdown from './counter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneSquareAlt, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

class App extends Component {
  componentDidMount() {
    document.title = 'AL OFOQ';
  }

  render() {
    return (
      <div>
        <div className="App">
          <span id="removePaading"></span>
          <div id="Content">
            <img src={logo} className="logo" />
            <h1> Coming Soon ... </h1>
            <p className="col-xl-4 col-lg-6 col-md-7 col-sm-8 col-10" > Our amazing website is under construction, we are working very hard to give you the best experience with us. </p>
            <hr className="col-xl-1 col-lg-1 col-md-2 col-sm-2 col-3" />
            <section id="counter">
              <Countdown timeTillDate="11 11 2020, 6:00 pm" timeFormat="MM DD YYYY, h:mm a" />
            </section>
          </div>
          <footer>
            <div >
              <div className="contact">
                <a href="https://wa.me/+201110046661" target="_blank">
                  <i> <FontAwesomeIcon icon={faPhoneSquareAlt} /> </i>
                  <p> | </p>
                  <i> <FontAwesomeIcon icon={faWhatsapp} /> </i>
                  <p> 01110046661 </p>
                </a>
              </div>
              <div className=" contact">
                <a href="mailto:info@elofoqeg.com" target="_blank">
                  <i> <FontAwesomeIcon icon={faEnvelope} /> </i>
                  <p> info@elofoqeg.com </p>
                </a>

              </div>
              <div className="contact">
                <i> <FontAwesomeIcon icon={faMapMarkerAlt} /> </i>
                <p className="addressText"> 1 Helmy Abdelati , Al Manteqah Al Thamenah, Nasr City </p>
              </div>

            </div>
          </footer>

        </div>

      </div>
    );

  }
}


export default App;
